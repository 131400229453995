
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { NextPage } from 'next';
import AsideLayout from 'modules/common/layouts/AsideLayout';
import HeaderLayout from 'modules/common/layouts/HeaderLayout';
import { Link } from 'modules/i18n';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import { useAuthModal } from 'modules/auth';
const Page500: NextPage = (): any => {
    const { whatsappMartin } = usePublicRuntimeConfig();
    const { session } = useAuthModal();
    const Layout = session ? AsideLayout : HeaderLayout;
    return (<Layout>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Cette page n&apos;existe pas</h1>
                <p className="mt-1 text-base text-gray-500">Accédez à une page existante via le menu</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6 items-center">
                <Link href="/" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                  Retour à vos missions
                </Link>
                <span>ou</span>
                <a href={whatsappMartin} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-primary bg-orange-100 hover:bg-orange-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" target="_blank" rel="noreferrer">
                  <span className="truncate">Contactez le support</span>
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>);
};
export default Page500;

    async function __Next_Translate__getStaticProps__195f5ef4a57__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f5ef4a57__ as getStaticProps }
  